.container.data-rooms {
    input[type=radio], input[type=checkbox] {
        position: relative;
        bottom: -1px;
    }
    
    h4 {
        margin-bottom: 15px;
    }

    .compare {
        align-items: flex-end;
        .form-group {
            margin: 0;
        }
    }

    .table th, .table td {
        vertical-align: middle;
    }

    .create {
        form {
            display: flex;
            width: 100%;
            align-items: flex-end;
        }

        .form-group {
            margin-bottom: 0;
            margin-right: 15px;
        }
    }
}
.glossary-tree__link.data-room {
    padding: 12px 19px;
    font-weight: 400;
    font-size: 13px;
    margin: 0;
}