
#user_permissions {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	position: relative;

	.plus {
		align-items: center;
		background: $lightGrey;
		border: 2px solid white;
		border-radius: 50%;
		color: #42526E;
		display: flex;
		font-size: 17px;
		font-weight: 400;
		height: 32px;
		justify-content: center;
		padding: 10px;
		text-align: center;
		width: 32px;
	}

	.results {

		left: 0;
		list-style: none;
		padding: 0;
		position: absolute;
		top: 42px;
		width: 100%;

		li {
			align-items: center;
			background: $lightBlue;
			color: #0F75BC;
			display: flex;
			font-weight: bold;
			height: 20px;
			justify-content: center;
			padding: 0;
			position: relative;
			z-index: 999;

			&:hover {
				color: black;
			}

			&:after {
				align-items: center;
				content: "\002B";
				display: flex;
				height: 100%;
				position: absolute;
				right: 8px;
			}

			:not(.disabled) {

				cursor: pointer;

			}

			&.disabled {

				color: #2A2A2A;

				&:after {
					display: none !important;
				}
			}

			&.exists {
				color: #739bbf;
				cursor: default;

				&:after {
					content: "\2713";
				}

				&:hover {
					color: unset;
				}

			}

		}

	}
}

.btn-edit-exp {
	background-color: #FFFFFF;
	border: 1px solid #DFE1E6;
	border-radius: 100px;
	box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
	box-sizing: border-box;
	color: #44526C;
	margin-left: 20px;
	outline: none;
	padding: 2px 18px;

	&:hover {
		background-color: #f7f7f7;
	}
}

.btn-delete-exp {
	background-color: #B80303;
	border: 1px solid #A60A0A;
	border-radius: 100px;
	box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
	box-sizing: border-box;
	color: #FFF;
	margin-left: 20px;
	outline: none;
	padding: 2px 18px;

	&:hover {
		background-color: #CA1515;
		color: #FFF;
	}
}

.form-group.add-rm-users {
	align-items: center;
	display: flex;
	margin: 0;

	input {
		border-radius: 20px;
		height: 31px;
		padding: 0;
		padding-left: 10px;
	}

	.close-input {
		align-items: center;
		background: url(../../assets/icons/close.svg);
		background-color: #363129;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 16px;
		border-radius: 50%;
		cursor: pointer;
		display: flex;
		height: 20px;
		justify-content: center;
		padding: 12px;
		position: absolute;
		right: 5px;
		width: 20px;
	}
}

.usrs-groups {
	align-items: center;
	display: flex;
	height: 39px;
	position: relative;

	img {
		border: 2px solid white;
		border-radius: 50%;
		width: 32px;
	}

	.user {
		position: absolute;
		right: 0;
		text-decoration: none;

		&.toslide {
			-moz-transition: margin-top 0.3s;
			-ms-transition: margin-top 0.3s;
			-o-transition: margin-top 0.3s;
			-webkit-transition: margin-top 0.3s;
			transition: margin-top 0.3s;

			&:hover {
				margin-top: -27px;
			}

		}

	}
}

.experiment-title-wrapper {
	.top {
		align-items: center;
		display: flex;
		justify-content: space-between;

		.title {
			margin-right: 16px;
		}

		.in-search {
			margin-right: -15px;
			min-width: 260px;
		}

		.group {
			align-items: center;
			display: flex;
			justify-content: center;
			position: relative;
		}

		.badge {
			background: $lightBlue;
			color: #0F75BC;
		}
	}

	.bottom {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-top: 26px;

		p {
			font-size: 13px;
			margin: 0;
		}
	}
}

.experiment-top-nav {
	align-items: center;
	display: flex;
	gap: 20px;
	justify-content: space-between;
	margin-bottom: 24px;

	h4.page-title {
		font-size: 20px;
		font-weight: bold;
		margin: 0;
		width: 250px;
	}

	#filterControl {
		width: 180px;
	}

	#exp-search {
		width: 494px;
	}

	.form-group {
		margin: 0;
	}

	select, button {
		height: 32px;
	}

	select {
		background: #FFFFFF;
		border: 1px solid #EBECF0;
		border-radius: 6px;
		box-sizing: border-box;
		font-size: 12px;
		padding-left: 10px;
		padding-right: 20px;
	}

	.spacer {
		border-right: 2px solid #DFE1E6;
		display: block;
		height: 24px;
	}

	.btn {
		align-items: center;
		background-color: #FFFFFF;
		border: 0.5px solid #DFE1E6;
		border-radius: 100px;
		box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
		box-sizing: border-box;
		color: #44526C;
		display: flex;
		font-size: 12px;
		font-weight: bold;
		height: 32px;
		line-height: 18px;
		outline: none;
		padding: 0 16px;
		text-align: center;

		&.new-exp {
			background-color: #0F75BC;
			border: 1px solid transparent;
			color: white;

			&:hover {
				background-color: #227dc7;
				color: #fff;
			}

			img {
				padding-right: 6px;
			}
		}
	}
}

.experiment-labels {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 18px;

	select {
		background: none;
		border: 0;
		color: #44526C;
		font-size: 12px;
		font-weight: bold;
		margin: 0;
		padding-left: 0;
	}

	.labels {
		.label {
			background: #E7F5FF;
			border-radius: 4px;
			color: #1666A0;
			font-size: 12px;
			font-weight: bold;
			height: 30px;
			margin-right: 16px;
			padding: 8px 14px;
			position: relative;
			width: 122px;

			img {
				cursor: pointer;
				position: absolute;
				right: -8px;
			}
		}
	}
}

.experiments-grid {
	display: grid;
	gap: 21px;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	padding-bottom: 38px;

	.prev-experiment {

		background: #FFFFFF;
		border: 1px solid #F4F5F7;
		border-radius: 8px;
		box-shadow: 0px 3px 4px -1px rgba(13, 31, 64, 0.03);
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		min-height: 300px;
		padding: 21px 24px;
		padding-bottom: 22px;
		position: relative;


		&.add-result {
			align-items: center;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			justify-content: center;

			a {
				background: none;
				border: none;
				outline: none;
				text-align: center;
				text-decoration: none;
			}

			h4 {
				color: #44526C;
				color: #44526C;
				font-size: 19px;
				font-weight: 400;
			}

			p {
				color: #44526C;
				font-size: 52px;
				line-height: 47px;
			}
		}

		.edit {
			align-items: center;
			background: white;
			border: 1px solid #e7e7e7;
			border-radius: 50%;
			cursor: pointer;
			display: flex;
			height: 23px;
			justify-content: center;
			position: absolute;
			right: 10px;
			top: 10px;
			width: 23px;

			&:hover {
				border: 1px solid #8e8e8e;
			}

			img {
				opacity: 0.7;
				width: 15px;
			}
		}

		hr {
			margin: 12px 0;
		}

		.title {
			font-size: 14px;
			font-weight: bold;
			margin-bottom: 12px;
		}

		.group, .start-end {
			p {
				color: #0D1F40;
				font-size: 13px;
				margin-bottom: 4px;

				span {
					color: #606C82;
				}
			}
		}

		.start-end {
			display: flex;
			justify-content: space-between;
			margin-top: 8px;

			p {
				margin: 0;
			}
		}

		.comment {
			.latest {
				color: #0D1F40;
				font-size: 13px;
				margin-bottom: 8px;
			}

			.title {
				color: #0D1F40;
				font-size: 11px;
				margin-bottom: 6px;
			}

			.content {
				color: #44526C;
				font-size: 12px;
				font-style: italic;
				font-weight: normal;
				margin-bottom: 9px;

				a {
					color: #0F75BC;
					font-size: 11px;
					font-style: normal;
					font-weight: bold;
					line-height: 13px;
					padding-left: 5px;
				}
			}
		}

		.btn {
			align-items: center;
			background-color: #FFFFFF;
			border: 1px solid #DFE1E6;
			border-radius: 100px;
			box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
			box-sizing: border-box;
			color: #44526C;
			display: flex;
			justify-content: center;
			margin-top: auto;
			padding: 2px 18px;
			width: 72px;

			&:hover {
				background-color: #f4f4f4;
			}
		}


		.signed {

			bottom: 30px;
			display: none;
			margin-right: 10px;
			position: absolute;
			right: 0;
			width: 76px;

			span {
				font-size: 11px;
				line-height: 11px;
				margin-right: 10px;
			}

			.icon {
				background: url(../../assets/icons/completed.svg);
				background-color: white;
				background-repeat: no-repeat;
				background-size: contain;
				height: 13px;
				width: 13px;
			}

		}

		&.is-signed {

			.signed {
				display: inline-flex;
			}

		}

	}

	.prev-lab {
		background: #FFFFFF;
		border: 1px solid #F4F5F7;
		border-radius: 8px;
		box-shadow: 0px 3px 4px -1px rgba(13, 31, 64, 0.03);
		box-sizing: border-box;
		min-height: 307px;
		padding: 21px 24px;
		padding-bottom: 22px;

		p.title {
			color: #0D1F40;
			font-size: 18px;
			font-weight: bold;
			line-height: 150%;
			margin-bottom: 5px;
		}


		p.subtitle {
			color: #0D1F40;
			font-size: 13px;
			font-weight: bold;
			line-height: 150%;
			margin-bottom: 5px;
		}

		span {
			color: #0D1F40;
			display: block;
			font-size: 12px;
			font-weight: normal;
			margin-bottom: 6px;
		}

		ul {
			padding-left: 10px;

			li {
				color: #0F75BC;
				font-size: 12px;
				font-weight: bold;
				margin-bottom: 3px;
				position: relative;

				svg {
					display: none;
				}

				&.signed {
					list-style: none;

					svg {
						display: block;
						position: absolute;
						margin-left: -20px;
						margin-top: 3px;
					}

				}

				a {
					font-weight: bold;

				}
			}
		}
	}
}


// new
.create-experiment, .edit-experiment {

	.btn {
		align-items: center;
		border-radius: 42px;
		display: flex;
		font-weight: 500;
		height: 34px;
		justify-content: center;
		margin-top: 20px;
		padding: 0;
		width: 160px;
	}

}


#science-experiment-result {

	//p, label, a, td, th{
	//    font-weight: 500;
	//}

}


//SIGNATURE
.signature-row {
	justify-content: flex-end;

	img {
		padding: 0px 45px;
	}

	hr {
		border-top: 2px solid #7C8698;
		margin-bottom: 5px;
		margin-top: 7px;
	}

	p {
		font-size: 14px;
		font-weight: 400;

		b {
			padding-left: 4px;
		}
	}
}

//DATA ROOM
.data-room {
	margin-top: 40px;
	/* width */
	::-webkit-scrollbar-track {
		background-color: white;
		border-radius: 14px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	::-webkit-scrollbar {
		background-color: white;
		width: 5px;
	}

	::-webkit-scrollbar-thumb {
		background-color: #DFE1E6;
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
	}

	.date-range-area {
		align-items: center;
		background: white;
		border: 1px solid #3490dc;
		border: 1px solid #eee;
		border-radius: 12px;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.025) !important;
		display: flex;
		gap: 5px;
		justify-content: space-between;
		padding: 3px 12px;
		padding-top: 4px;

		.arrow {
			height: 14px;
			width: 14px;
		}
	}

	.companies {
		display: flex;
		justify-content: center;

		button {
			align-items: center;
			background: white;
			border: 1px solid #eee;
			border-radius: 12px;
			box-shadow: 0 0.125rem 0.15rem rgba(0, 0, 0, 0.025) !important;
			color: #44526C;
			display: flex;
			font-size: 12px;
			font-weight: 500;
			gap: 5px;
			justify-content: space-between;
			letter-spacing: 0.02em;
			padding: 3px 12px;
			padding-top: 4px;
			text-align: left;
			width: unset;
		}

		p {
			margin: 0;
		}

		.radio-item:checked ~ label {
			background: #4c98cc;
			color: white;
		}

		input {
			display: none;
			opacity: 0;
		}

		.dropdown-menu {
			align-items: baseline;
			background-color: #ffffff;
			border: none;
			border-radius: 10px;
			box-shadow: 0px 3px 50px rgb(4 15 34 / 13%);
			flex-direction: column;
			gap: 10px;
			justify-content: center;
			left: unset !important;
			min-width: 160px;
			padding: 21px 0px;
			right: unset;
			top: 4px !important;
			width: max-content;
			z-index: 9999;

			label {
				cursor: pointer;
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 0.02em;
				margin-bottom: 0;
				padding: 0 21px;
				text-align: left;
				width: 100%;

				&:hover {
					background: #4c98cc;
					color: white;
				}
			}

			p {
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 0.02em;
				margin-bottom: 0;
				padding: 0 21px;
				text-align: left;
				width: 100%;
			}
		}
	}

	.filters {
		form {
			align-items: center;
			display: flex;
			gap: 20px;
			justify-content: center;
		}

		button {
			border-radius: 12px;
			box-shadow: 0 0.125rem 0.15rem rgba(0, 0, 0, 0.025) !important;
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	#drRangeSelector {
		background: none;
		background: url(../../assets/icons/arrow_down_c.svg);
		background-position: right;
		background-repeat: no-repeat;
		background-size: 14px;
		border: none;
		color: #0F75BC;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.02em;
		outline: none;
		position: relative;
		text-align: left;
		width: 200px;

		&.active {
			background: url(../../assets/icons/arrow_down_c_r.svg);
			background-position: right;
			background-repeat: no-repeat;
			background-size: 14px;
		}

		&::-webkit-input-placeholder {
			color: #44526C;
		}

		&:-moz-placeholder {
			color: #44526C;
			opacity: 1;
		}

		&::-moz-placeholder {
			color: #44526C;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: #44526C;
		}
	}

	.grid-3-2 {
		display: grid;
		gap: 24px;
		grid-template-columns: 4fr 3fr;
	}

	.title-area {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}

	.title {
		font-size: 20px;
		font-weight: bold;
		line-height: 24px;
		margin: 0;
	}

	.card {
		margin: 0;
		padding: 0;

		&.graph {
			display: flex;
			flex-direction: row;

			.left {
				border-right: 1px solid #F4F5F7;
				width: 100%;
			}

			.right {
				align-items: center;
				display: flex;
				padding-bottom: 54px;
				padding-left: 40px;
				width: 330px;

				.legend {
					margin-right: 34px;
					width: 100%;
				}

				.row {
					align-items: center;
					display: flex;
					justify-content: space-between;
					margin-bottom: 20px;
				}

				p {
					color: #283856;
					font-size: 12px;
					font-weight: normal;
					line-height: 11px;
					margin: 0;
				}

				span {
					color: #5DB082;
					font-size: 12px;
					font-weight: 400;

					/* Green/G5 */

					line-height: 14px;
				}
			}
		}

		canvas {
			max-height: 500px;
		}

		#trafficChart {
			padding: 20px;
		}

		.top {
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding: 26px 32px;
			padding-bottom: 24px;

			.form-control, .form-group {
				margin-bottom: 0;
			}

			select {
				border: 1px solid #eceef0;
				font-size: 12px;
				font-weight: normal;
				height: 32px;
				line-height: 11px;
				padding-left: 10px;
				width: 260px;
			}
		}

		.card-title {
			font-size: 16px;
			font-weight: 500;
			margin: 0;
		}

		.header {
			color: #98A0AE;
			font-size: 10px;
			font-weight: 400;
			padding: 0 32px;
		}

		.show-more {
			align-items: center;
			display: flex;
			height: 48px;
			justify-content: center;

			p {
				color: #0F75BC;
				font-size: 12px;
				font-weight: 600;
				line-height: 18px;
				margin: 0;
			}
		}

		.header {
			display: grid;
			gap: 10px;

			span {
				color: #98A0AE;
				font-size: 10px;
				font-weight: 400;
				text-transform: uppercase;
			}
		}

		.line {
			align-items: center;
			border-bottom: 1px solid #EBECF0;
			display: grid;
			gap: 10px;
			height: 52px;
			padding: 0 32px;

			&.no-data {
				color: #98A0AE;
				font-size: 15px;
				font-weight: 500;
			}

			&:last-child {
				border-bottom: none
			}

			p {
				font-size: 12px;
				font-weight: 400;
				margin: 0;
			}

			.img-name {
				align-items: center;
				display: flex;
				gap: 16px;

				img {
					height: 28px;
					width: 28px;
				}

				p {
					font-size: 13px;
					font-weight: bold;
					margin: 0;
				}
			}
		}

		&.long {
			grid-column-end: 3;
			grid-column-start: 1;

			.header {
				grid-template-columns: 6fr 2fr 3fr 3fr 3fr;

				span {
					align-items: center;
					display: flex;
					gap: 5px;

					&.name {
						padding-left: 44px;
					}
				}
			}

			.filter-down, .filter-up {
				cursor: pointer;
			}

			.filter-up {
				filter: grayscale(1);

				img {
					transform: rotate(180deg);
				}
			}

			.lines {
				height: 300px;
				overflow-y: auto;

				.line {
					grid-template-columns: 6fr 2fr 3fr 3fr 3fr;
				}
			}
		}

		&.users {
			.header {
				grid-template-columns: 6fr 1fr 2fr 2fr;

				.name {
					padding-left: 44px;
				}
			}

			.lines {
				height: 208px;
				overflow-y: auto;

				.line {
					align-items: center;
					display: grid;
					gap: 10px;
					grid-template-columns: 6fr 1fr 2fr 2fr;
					height: auto;
					min-height: 52px;
					padding: 0 32px;
				}
			}
		}

		&.login-by-usr {
			.header {
				grid-template-columns: 3fr 2fr 3fr;
			}

			.lines {
				height: 250px;
				overflow-y: auto;

				.line {
					align-items: center;
					display: grid;
					gap: 10px;
					grid-template-columns: 3fr 2fr 3fr;
					height: auto;
					min-height: 52px;
					padding: 0 32px;

					p.name {
						font-size: 13px;
						font-weight: bold;
						margin: 0;
					}
				}
			}
		}

		&.countries {
			.header {
				grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr;
			}

			.lines {
				overflow-y: auto;

				.line {
					align-items: center;
					display: grid;
					gap: 10px;
					grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr;
					height: auto;
					min-height: 52px;
					padding: 0 32px;

					p.name {
						font-size: 13px;
						font-weight: bold;
						margin: 0;
					}
				}
			}
		}


	}
}
