// Fonts
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
$navbar-light-color: #000;
@import '~bootstrap/scss/bootstrap';
@import '~flatpickr/dist/flatpickr.min.css';
@import '~slick-carousel/slick/slick.scss';
@import './partials/secondary-menu';
@import './partials/footer.scss';
@import './partials/breadcrumbs.scss';
@import './templates/storage.scss';
@import './templates/experiments.scss';
@import './templates/reports.scss';
@import './templates/users.scss';
@import './templates/login.scss';
@import './partials/splash-screen.scss';
@import './templates/dashboard.scss';
@import './templates/search-alerts.scss';
@import './templates/data-rooms.scss';

.space-left {
    margin-left: 5px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    color: #0D1F40;
}

.navbar-light .navbar-toggler{
    outline: none;
    border: none;
}

.background-success{
    background-color: $success;
}

.background-danger{
    background-color: $danger;
}

.background-neutral{
    background-color: #999;
}

.grayscale{
	filter: grayscale(1);
}

.disabled-link {
	pointer-events: none;
}

* {
	//"Gotham SSm A", "Gotham SSm B"
    font-family: Arial, sans-serif;
    font-weight: 300;
}
.v-hidden {
    visibility: hidden;
}

.container{
    max-width: 1312px;
}

.btn {
    font-weight: 500;
}

button{
    outline: none!important;
}

.form-control{

    border-radius: 6px;
    border-color: #EBECF0;

    &:focus{
        border-color: #48b5ff;
        outline: none;
        box-shadow: none;
    }

    &:disabled{
        background-color: #e9ecef !important;
    }



    &.invalid{
        border: $danger 1px solid;
    }

    &[type="text"]{
        filter: drop-shadow( inset 0px 4px 4px rgba(0, 0, 0, 0.25));
    }



}
.users__list {
    .delete-button-sm {
        font-size: 12px;
        width: 30px !important;
        height: 30px !important;
        border-radius: 50%;
    }

    .toggle-button-sm {
        width: 60px !important;
        font-size: 15px;
    }

    .sort-button {
        color: #000;
        justify-content: left! important;
        font-weight: 500;
        vertical-align: center;
        text-decoration: none !important;

        .sort-icon {
            color: #bbb;
            font-size: 12px;
            margin-right: 5px;
        }
    }
}

.form-control[readonly]{
    background: inherit;
}

.companies {
    .dropdown-toggle {
        width: 130px;
    }

    .item {
        label {
            padding-top: 5px !important;
            padding-bottom: 5px !important;
        }
    }
}

.loading-wrapper {
    display: none;

    .loading {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;

        .loading-backdrop {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #000;
            opacity: .1;
        }
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #0f75bc;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

.float-end {
    justify-content: flex-end;
}

.content-seven-cols {
    grid-template-columns: 6fr 2fr 3fr 3fr 3fr 3fr 3fr!important;
}

.content-three-cols {
    grid-template-columns: 3fr 2fr 3fr!important;
}

.content-four-cols {
    grid-template-columns: 3fr 2fr 3fr 3fr!important;
}

//breadcrumbs
.breadcrumbs{
    &.main{
        margin-bottom: 20px;
    }
    .breadcrumbs__item{
        margin-right: 3px;
        font-size: 12px;
        &:after{
            font-size: 12px;
            color: #7C8698;
            margin-left: 6px;
        }
        &:last-child{
            .breadcrumbs__item__link{
                color: #000;
            }
        }
    }
    .breadcrumbs__item__link{
        font-weight: 600;
        font-size: 12px;
        color: #7C8698;
    }
}

.btn-filled {
    height: 32px;
    line-height: 1.2;
    background: #0F75BC;
    color: #fff;
    font-weight: 500;
    padding: 7px 20px;
    font-size: 0.9rem;
    outline: 0;
    text-decoration: none!important;

    &--border {
        border: 1px solid #eee !important;
        background: #fff;
        color: #000;
        margin-right: 15px;

        &:hover {
            color: #000!important;
        }
    }

    &-right {
        float: right;
    }

    &:hover {
        color: #fff;
    }

    &-rounded {
        border-radius: 50px;
    }

    .btn-icon {
        margin-right: 10px;
    }
}

.flashalert{
    margin: 0 -1.2rem  1.2rem -1.2rem;
}


.in-search{
    height: 32px !important;
    border: none;
    background: #FFFFFF;
    border: 1px solid #EBECF0;
    box-sizing: border-box;
    border-radius: 6px;
    background: url('../assets/icons/search.svg');
    background-color: white;
    background-repeat: no-repeat;
    background-position: 7px;
    padding-left: 33px;
    outline: none;
    font-weight: 500;
    font-size: 12px;
}
#grey-bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0;
    transition: all 0.2s ease;
    z-index: 99;
    pointer-events: none;
}

.splash-screens{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: white;
    .screen{
        width: 100vw;
        height: 100vh;
        background: #0F75BC;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &._0{
            position: relative;
            z-index: 999999999999;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px;
            text-align: left;
            p{
				//"Gotham SSm A", "Gotham SSm B"
                font-family: Arial;
                font-weight: 300;
                font-size: 28px;
                color: white;
                max-width: 577px;
            }
        }
        &._1{
            background-image: url("../assets/slide_1.jpg");
            background-position-y: -400px;
        }
        &._2{
            background-image: url("../assets/slide_2.jpg");
        }
        &._3{
            background-image: url("../assets/slide_3.jpg");
        }
        &._4 {
            position: relative;
            overflow: scroll;
            background: #fff;

            & img {
                max-width: 100%;
            }

            h2 {
                margin: 40px;
                font-weight: 800;
                font-size: 20px;
                margin-bottom: 20px;
            }

            .glossary-tree {
                margin-bottom: 80px;
                @media all and (max-width: 769px) {
                    margin-bottom: 150px;
                }
            }
        }
        h1{
            color: white;
            font-size: 70px;
            position: absolute;
            right: 335px;
            top: 88px;
			//"Gotham SSm A", "Gotham SSm B"
            font-weight: 300;
        }
        h3{
            color: white;
            font-size: 45px;
            position: absolute;
            right: 140px;
            top: 168px;
            font-weight: 300;
        }
        .bottom-texts{
            position: absolute;
            bottom: 140px;
            left: 0;
            right: 0;
            text-align: center;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 42px;
            font-weight: 500;
            p{
                color: white;
                margin: 0;
                padding: 0px 16px;
            }
        }
        .bottom-circles{
            position: absolute;
            bottom: 75px;
            left: 0;
            right: 0;
            text-align: center;
            color: white;
            display: grid;
            gap: 10px;
            grid-template-columns: 1fr 1fr 1fr;
            width: 44px;
            margin: 0 auto;
            span{
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.5);
                &.active{
                    background: white;
                }
            }
        }
        .filter{
            background: rgba(13, 31, 64, 0.2);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .close-splash{
        width: 40px;
        height: 40px;
        top: 25px;
        right: 25px;
        border-radius: 50%;
        border: none;
        outline: none;
        background: url("../assets/icons/close.svg");
        background-color: #0f75bc;
        z-index: 999;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;

    }
    .btns-set{
        position: absolute;
        bottom: 40px;
        display: flex;
        justify-content: space-between;
        left: 50px;
        right: 50px;
        z-index: 999;
        button{
            border: 0;
            outline: 0;
            width: 159px;
            height: 48px;
            box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
            border-radius: 100px;
            font-size: 16px;
            font-weight: 600;
            color: #44526C;
            transition: all 0.2s ease;
            &.started{
                display: none;
                position: fixed;
                right: 90px;

            }
            &.skip{
                background: rgba(255, 255, 255, 0.5);
                color: white;
            }
            &.glossary {
                display: none;
                bottom: 30px;

            }
            &:hover{
                background: #efefef;
                color: #44526C;
                img{
                    margin-left: 25px;
                }
            }
            img{
                margin-left: 19px;
                position: relative;
                top: -1px;
                transition: all 0.2s ease;
            }
        }
    }
}
@media screen and (max-width: 1040px) {
    .splash-screens .screen._1{
        background-position-y: unset;
    }
}

@media all and (max-width: 800px) {
    .splash-screens {
        .screen {
            h1 {
                right: 0;
                left: 38px;
            }
            h3 {
                right: 0;
                left: 165px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .splash-screens .btns-set button.skip{
        display: none;
    }
    .splash-screens .btns-set button.started {
        right: 0;
        bottom: 30px;
        margin: 0 30px;
        width: 159px;
    }
    .splash-screens .btns-set{
        bottom: 36px;
    }
    .splash-screens .btns-set button{
        width: 100%;
    }
    .splash-screens .screen._1{
        padding-bottom: 100px;
        p{
            font-size: 20px;
            text-align: left;
        }
    }
    .splash-screens .screen .bottom-texts{
        bottom: 162px;
    }
    .splash-screens .screen .bottom-circles{
        bottom: 132px;
    }
    .splash-screens .screen .btns-set{
        left: 30px;
    }
    .splash-screens .close-splash{
        display: block;
    }
}
@media screen and (max-width: 540px) {
    .splash-screens .screen h1{
        font-size: 36px;
        font-weight: 300;
        left: 50px;
        top: 40px;
    }
    .splash-screens .screen h3{
        font-size: 26px;
        left: 120px;
        top: 91px;
        font-weight: 300;
    }
}
.navbar {
    min-height: 65px;
    z-index: 10;
}


#navbarSupportedContent .closebtn{
    display: none;
}

.navbar-nav {
    align-items: center;
}

#navbarSupportedContent{
    .nav-item {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        &:hover {
            .sub-a-content {
                display: flex;
            }
        }

        a {
            font-weight: 400;
            font-size: 14px;

            &.tab1{
                padding-left: 25px;
            }

        }

    }
}

.sub-a-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    z-index: 9999;
    margin-top: 37px;
    top: 2px;
    align-items: baseline;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 3px 50px rgb(4 15 34 / 13%);
    padding: 21px 24px;
    flex-direction: column;
    gap: 10px;
    width: max-content;
    hr {
        margin: 0;
        margin-left: -24px;
        width: calc(100% + 48px);
    }
    .nav-link {
        padding: 0;
    }
    .inner {
        transform: rotate(45deg);
        background-color: white;
        width: 100px;
        height: 100px;
        top: 20px;
        left: -50px;
        position: relative;
        border-radius: 20px;
    }

    .outer {
        position: absolute;
        top: -74px;
        left: 0;
        right: 0;
        width: 70px;
        height: 140px;
        overflow: hidden;
        margin: 0 auto;
        transform: rotate(
        -90deg
        ) scale(0.2);
    }
}

.logo-little{
    display: none;
}
//NAV MOBILE

@media screen and (max-width: 768px) {
    .logo-little{
        position: absolute;
        top: 21px;
        left: 32px;
        font-size: 36px;
        margin-left: 0;
        color: #606c82;
        text-decoration: none;
        display: block;
        width: 28px;
        height: auto;
    }
    #navbarSupportedContent{
        z-index: 999999!important;
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        background-color: white;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
        display: block!important;
        li{
            a{
                padding: 8px 8px 8px 32px;
                text-decoration: none;
                font-size: 17px;
                color: #818181;
                display: block;
                transition: 0.3s;
                font-weight: 700;
            }
        }
        .closebtn {
            position: absolute;
            top: 0;
            right: 25px;
            font-size: 36px;
            margin-left: 50px;
            color: #606c82;
            text-decoration: none;
            display: block;
        }
    }
    .logout-dropdown{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-left: 0;
        #navbarDropdown{
            display: none;
        }
        .dropdown-menu{
            display: block;
            border-left: 0;
            border-radius: 0;
            border-right: 0;
            border-top: 1px solid #ececec;
        }
    }
    .navbar-nav.right{
        border-top: 1px solid #ececec;
        padding-top: 20px;
    }
}

.cursor-pointer{
    cursor: pointer;
}

.cursor-default{
    cursor: default !important;
}

.logo {
    text-align: left;

    img {
        width: 140px;
        &.slug-lazard {
            width: 236px;
        }

    }
}

.nav-item{

    padding: 0 9px;


    a {
        cursor: pointer;
        font-weight: 500;
        color: #000;

        &.active {
            color: #1e77bd!important;
        }
    }
}

@media all and (max-width: 1200px) {
    .nav-item {
        padding: 0;
    }
}

@media all and (max-width: 992px) {
    .nav-item {
        a {
            font-size: .8rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

@media all and (min-width: 768px) {
    .nav-item {
        &.logout-dropdown {
            height: 32px;
            line-height: 1.1;
            padding: 0px 10px;
            border: 1px solid #e1e1e1;
            border-radius: 50px;
        }
    }
}

.maxwidth-100{
    max-width: 100%;
}


.image-component{

    .image-wrapper{

        .btn.delete{
            position: absolute;
            right: 24px;
            top: 10px;
        }

    }
}

.header_container {
    padding: 20px;
}


/*******************
chat application Page
******************/

.chat-main-box {
    //position: relative;
    //overflow: hidden;
    width:100%;

    .chat-left-aside {
        position: relative;
        width: 250px;
        float: left;
        z-index: 9;
        top: 0px;
        border-right: 1px solid #78828C;
        .open-panel {
            display: none;
            cursor: pointer;
            position: absolute;
            left: -webkit-calc(100% - 1px);
            top: 50%;
            z-index: 100;
            background-color: #fff;
            -webkit-box-shadow: 1px 0 3px rgba(0, 0, 0, .2);
            box-shadow: 1px 0 3px rgba(0, 0, 0, .2);
            border-radius: 0 100px 100px 0;
            line-height: 1;
            padding: 15px 8px 15px 4px;
        }
        .chat-left-inner {
            position: relative;
            .form-control {
                height: 60px;
                padding: 15px;
                background-image: linear-gradient(#1E88E5, #1E88E5), linear-gradient(#78828C, #78828C);
            }
            .style-none {
                padding: 0px;
                li {
                    list-style: none;
                    overflow: hidden;
                    a {
                        padding: 20px;
                        &:hover,
                        &.active {
                            background: #EBF3F5;
                        }
                    }
                }
            }
        }
    }

    &.fw {
        width: calc(100% - 40px);
    }

    .chat-list {

        width: 100%;
        margin-bottom:100px;

        .chat-text {
            border-radius: 6px;
        }


    }

    .send-chat-box {

        .chat-input, .chat-input:hover, .chat-input:active{
            overflow: hidden;
            height:54px;
            padding:15px 20px;
            background-color: $light !important;
        }

    }

}


.in_board_channel .chat-main-box #notifications{
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 2;
	background-color: #FFF;
	width: 100%;
	padding: 10px 15px;
	border-bottom: 1px solid rgba(175, 175, 175, 0.1);
}

.list-style-none{
    list-style-type: none;
}


/*******************/
/*Chat widget*/
/*******************/

.chat-input input{
    height: 48px!important;
    background: #F4F5F7;
    border: 1px solid #EBECF0;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #7C8698;
}

.chat-input.typing {
    input {
        border-radius: 8px 0px 0px 8px;
    }
    .btn {
        border-radius: 0px 8px 8px 0px;
        height: 48px;
        width: 48px;
        background-color: #1E8BD8;
        display: flex;
        align-content: center;
        justify-content: center;
    }
}

.chat-list {
    margin: 0px;
    padding: 0px;
    .separator {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
        hr {
            height: 2px;
            position: absolute;
            left: -40px;
            right: -40px;
            margin: 0;
        }
        .btn {
            z-index: 1;
            opacity: 1;
            height: 26px;
            background: #FFFFFF;
            border: 1px solid #EBECF0;
            box-sizing: border-box;
            border-radius: 100px;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: #B4BAC4;
            padding: 0 14px;
        }
    }

    li {

        list-style: none;
        margin-top: 20px;
        position: relative;
        display: flex;
        gap: 8px;

        .chat-img {
            width: 35px;
            display: inline-block;
            float: left;

            img {
                width: 35px;
                height: 35px;
                border-radius: 100%;
            }
        }

        .chat-content {
            max-width: 60%;
            flex-direction: row-reverse;
            padding: 18px 24px;
            justify-content: space-between;
            gap: 20px;
            align-items: baseline;
            border-top-left-radius: 0;
            display: inline-block;
            margin-bottom: 5px;
            position: relative;
            background-color: #FFFFFF;
            font-size: 12px;
            line-height: 150%;
            color: #0D1F40;
            border: 1px solid #EBECF0;
            box-sizing: border-box;
            border-radius: 0px 8px 8px 8px;
            width: auto;

            h5 {
                color: #78828C;
                margin: 0;
            }

            .chat-user {
                font-weight: bold;
                font-size: 13px;
                line-height: 12px;
                color: #0D1F40;
            }

            .box {
                border-top-left-radius: 0;
                display: inline-block;
                margin-bottom: 5px;
                position:relative;

                width: 100%;
                text-align: left;
                max-width: 100%;
                padding: 0;
                .text {
                    font-size: 12px;
                    color: #0D1F40;

                    font-style: normal;
                    font-weight: normal!important;
                    font-size: 12px;
                    line-height: 156%;
                    margin-bottom: 0;
                }

                a {
                    color: #FFFFFF;
                }
            }
        }

        &.odd {
            .chat-content {
                text-align: right;
                width: calc(100% - 90px);
            }
            .box {
                clear: both;

            }
        }

        &.odd + .odd {
            margin-top: 0px;
        }

        .chat-time {
            font-weight: bold;
            font-size: 11px;
            line-height: 134%;
            color: #B4BAC4;
        }

        .usr-date {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            margin-bottom: 7px;
        }

        // Nth message from the same user (not first)
        &.sameuser{

            margin-top:0px;

            .chat-content{

                margin-left: 43px;
                display: flex;
                .usr-date {
                    margin: 0;
                }

                .box{
                    margin: 0;
                    &:after{
                        content: none;
                    }

                }

            }

            &.reverse{

                .chat-content{
                    margin-right: 43px;
                    max-width: 60%;
                    flex-direction: row-reverse;
                    display: inline-flex;
                    padding: 18px 24px;
                    justify-content: space-between;
                    gap: 20px;
                    align-items: baseline;
                }

                .box {
                    background: #0D1F40;
                    .text {
                        font-size: 12px;
                        line-height: 150%;
                        color: #FFFFFF;
                    }
                }

            }

            .chat-img{
                display: none;
            }

            .chat-user{
                display: none;
            }

        }

        // Reverse
        &.reverse {
            .chat-time {
                color: #7C8698;
            }

            text-align: right;
            display: flex;
            flex-direction: row-reverse;
            gap: 8px;
            clear: both;
            .box{
                background: #0D1F40;
                border: 0;
                padding: 0;
                .text {
                    font-size: 12px;
                    line-height: 150%;
                    color: #FFFFFF;
                }
            }

            .chat-img{
                float: right;
                margin-bottom: 5px;
            }

            .chat-time {
                right:-35px;
                //right:auto;
            }

            .chat-content {
                border-top-left-radius: 0;
                display: inline-block;
                margin-bottom: 5px;
                position: relative;
                background-color: #FFFFFF;
                font-size: 12px;
                line-height: 150%;
                color: #0D1F40;
                border: 1px solid #EBECF0;
                box-sizing: border-box;
                background: #0D1F40;
                border-radius: 8px 0px 8px 8px;
                width: auto;
                .chat-user {
                    color: white;
                    margin: 0;
                }
            }
        }

    }
}

.pagetitle{
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    width: 250px;
}

.card{
    padding: 29px 32px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    border: 1px solid $lightGrey;
    border-radius: 8px;
    margin-bottom: 24px;
    .title{
        font-size: 20px;
        font-weight: 600;
        margin: 0;
    }
}

.card-tabs{
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    border: 1px solid $lightGrey;
    border-radius: 8px;
    background: white;
    .nav-tabs{
        padding-bottom: 0;
        border-bottom: 0;
        background: #F4F5F7;
        .nav-link{
            font-size: 14px;
            padding: 0;
            padding-top: 0;
            padding-bottom: 0;
            margin-right: 0;
            border: 0;
            border-bottom: 2px solid transparent;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            display: flex;
            height: 46px;
            padding: 0 32px;
            color: #606C82;
            background: #F4F5F7;
            border: 1px solid transparent;
            box-sizing: border-box;
            border-radius: 8px 8px 0px 0px;
            border-bottom: 0;
        }
    }
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
        color: #1E8BD8;
        border: 1px solid #F4F5F7;
        background: #ffffff;
        border-bottom: 0;
    }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus{
        color: #1E8BD8;
    }
    .tab-content{
        padding: 38px 32px;
        padding-bottom: 0;
        .section{
            border-bottom: 1px solid $lightGrey;
            margin-bottom: 38px;
            h4{
                font-size: 14px;
                font-weight: 800;
                margin-bottom: 21px;
            }
            p{
                color: $blueBlack;
                font-size: 13px;
                font-weight: 600;
                &.small{
                    font-size: 12px;
                }
                &.grey{
                    color: #7C8698;
                }
            }
            .p-group{
                p{
                    margin-bottom: 0;
                }
                margin-bottom: 22px;
                .grey{
                    line-height: 16px;
                    color: #7C8698;
                    margin-bottom: 7px;
                }
            }
        }
    }
}

.badge{
    padding: 4px 14px;
    font-weight: 600;
    font-size: 11px;
}


.password-strength{

    .text{
        font-weight: bold;
    }

    &.weak{

        .text{
            color: $red;
        }

    }

    &.medium{

        .text{
            color: $blue;
        }

    }

    &.strong{

        .text{
            color: $green;
        }

    }

}



#user_permissions{

    .user{


        .remove{
            display: none;
        }

        &:hover{

            .remove {
                display: block;
                position: absolute;
                right: 5px;
                top: -6px;
                font-size: 18px;
                font-weight: 500;
                cursor: pointer;
                background-color: red;
                border-radius: 50%;
                width: 18px;
                height: 18px;
                line-height: 18px;
                text-align: center;
                color: #fff;
                font-style: normal;
                transform: rotate(45deg);
            }

        }

    }


}


#blog_post_editor{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 2;
    overflow-y: scroll;
}


.tox .tox-statusbar{
    display: none !important;
}

.btn-white {
    background-color: #FFFFFF;
    border: 1px solid #DFE1E6;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
    border-radius: 100px;
    color: #44526C;
    padding: 2px 18px;
    outline: none;
    font-weight: 500;
    padding: 6px 15px;
    font-size: 12px;
}

//communications
.communications-sub-menu{
    background: #0D1F40;
    top: -1.5rem !important;
    position: relative;
    align-items: center;
    font-size: 12px;
    height: 40px;
    a {
        color: white;
        font-weight: 600;
        font-size: 12px;
        outline: none;
        box-shadow: none!important;
        &:hover{
            color: #1E8BD8;
        }
        &.active{
            color: #1E8BD8;
        }
    }
}

.white{
    color: white!important;
}

.communications-template{
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    height: 700px;
    .card-title{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #0D1F40;
    }
    .description{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #0D1F40;
    }
    .card{
        height: 312px;
        padding: 24px;
        border-radius: 12px;
        border: 1px solid #fff;
        box-shadow: 0 0.125rem 0.75rem rgba(0, 0, 0, 0.045) !important;
        .card-body{
            padding: 0;
        }
        .btn.options, .btn.delete {
            display: none;
        }
        .pr-4{
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }
        .btn{
            font-size: 11px;
            padding: 0;
            height: 30px;
            max-width: 100px;
            padding: 0 20px;
            padding-top: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background: #0F75BC;
            color: white;
        }
    }
    .group {
        small, .small {
            margin-bottom: 8px;
            font-weight: 350;
            font-size: 10px;
            line-height: 12px;
            color: #98A0AE;
        }
        .st{
            .card {
                height: 312px;
                a{
                    width: auto;
                    font-size: 11px;
                    background: none;
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    display: inline;
                    color: #0F75BC;
                    font-weight: 600;
                }
                .card-bodyp-0{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    .description {
                        display: inline;
                    }
                    .btn.btn-white{
                        display: inherit;
                        width: auto;
                        background: none;
                        border: none;
                        box-shadow: none;
                        padding: 0;
                        display: inline;
                        color: #0F75BC;
                        &:hover{
                            color: #718aa0;
                        }
                    }
                }
            }
        }
        .nd{
            .card {
                height: 324px;
                padding: 12px;
                .title-area{
                    position: absolute;
                }
                .btn {
                    padding: 0;
                    font-size: 11px;
                    line-height: 18px;
                    height: 32px;
                }
                .card-body{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    height: 100%;
                    .card-content {
                        padding: 10px;
                        padding-right: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .title-area {
                            position: static;
                        }
                    }
                    .pr-4{
                        margin-top: 26px;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 12px;
                    }
                }
            }
        }
        &.right{
            display: grid;
            gap: 24px;
            grid-template-columns: 3fr 2fr;
            .one{
                .h{
                    .card {
                        height: 403px;
                        background: #0D1F40;
                        color: white;
                        .description {
                            color: white;
                        }
                        .btn{
                            background: white;
                            color: #44526C;
                        }
                    }
                }
                .n{
                    .card {
                        height: 233px;
                        padding: 19px 24px;
                        .title-area {
                            margin-bottom: 15px;
                        }
                        .card-body{
                            display: grid;
                            grid-template-columns: 2fr 3fr;
                            gap: 16px;
                            height: 100%;
                            .card-content{
                                padding-top: 5px;
                            }
                            img {
                                width: 100%;
                                height: 100%;
                                -o-object-fit: cover;
                                object-fit: cover;
                                border-radius: 12px;
                            }
                        }
                        a{
                            display: inherit;
                            width: auto;
                            font-size: 11px;
                            background: none;
                            border: none;
                            box-shadow: none;
                            padding: 0;
                            display: inline;
                            color: #0F75BC;
                            font-weight: 600;
                            &:hover{
                                color: #718aa0;
                            }
                        }
                    }
                }
            }
        }
        .pre-card{
            .title-area{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                .title{
                    color: #98A0AE;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 13px;
                }
                a{
                    color: #0F75BC;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
            &.sm{
                &.video{
                    .card{
                        background-image: url("../assets/doctor.jpg");
                        background-color: transparent;
                        background-size: cover;
                        background-position: center;
                        position: relative;
                        .card-body{
                            position: relative;
                            z-index: 9999;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            margin-top: 40px;
                            .card-title{
                                color: white;
                                text-transform: uppercase;
                                font-size: 14px;
                            }
                            .btn{
                                background: none;
                                letter-spacing: 0.5px;
                                padding: 0;
                                position: relative;
                                &:after{
                                    content: "";
                                    height: 18px;
                                    width: 18px;
                                    background-image: url(/images/see-more.svg?3bc3219…);
                                    margin-left: 6px;
                                    background-repeat: no-repeat;
                                }
                            }
                        }
                        .overlay{
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            opacity: 0.3;
                            background: #000000;
                            border-radius: 12px;
                        }
                    }
                }
                .card{
                    height: 231px;
                }
            }
            &.xs{
                .card{
                    background: #63BDFB;
                    height: 150px;
                    padding: 10px;
                    .card-body{
                        text-align: center;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        .card-title{
                            font-weight: bold;
                            letter-spacing: 0.5px;
                            font-size: 14px;
                            line-height: 18px;
                            text-align: center;
                            text-transform: uppercase;
                            color: #FFFFFF;
                            margin-bottom: 18px;
                        }
                        .btn{
                            color: #44526C;
                            background: white;
                        }
                    }
                }
            }
        }
    }
}
//media communications main page
@media screen and (max-width: 1250px) {
    .communications-template {
        grid-template-columns: 1fr;
    }
    .communications-template .group .st .card{
        height: auto;
    }
}

@media screen and (max-width: 700px) {
    .communications-template .group .nd .card {
        height: auto;
    }
    .communications-template .group .nd .card .card-body{
        display: block;
    }
    .communications-template .group .nd .card .card-body .card-content{
        margin-bottom: 20px;
    }
    .communications-template .group.right{
        display: block;
    }
    .communications-template .group.right .one .h .card, .communications-template .group.right .one .n .card, .communications-template .group .pre-card.sm .card{
        height: auto;
    }
}

@media screen and (max-width: 420px) {
    .communications-template{
        margin-left: -15px;
        margin-right: -15px;
    }
    .communications-template .group.right .one .n .card .card-body{
        display: block;
    }
}
.edit-btn{
    cursor: pointer;
    width: 23px;
    height: 23px;
    position: absolute;
    background: white;
    border: 1px solid #e7e7e7;
    border-radius: 50%;
    background-size: 14px;
    padding: 7px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../assets/icons/edit-pen.svg");
}

.card{
    border-radius: 12px;
    position: relative;
}

.communications-category-page{
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 34px;
    .cat-title{
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #0D1F40;
        margin-bottom: 26px;
    }
}

.suggested-news{
    .grid-components{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;
    }
    .card{
        margin: 0;
        padding: 0;
        .card-body{
            padding: 16px;
            padding-top: 0;
            padding-bottom: 20px;
        }
        .main-img{
            height: 150px;
            object-fit: cover;
        }
        .card-title{
            font-size: 16px;
            margin-bottom: 8px;
        }
        .btn-white{
            display: none;
        }
        .description{
            font-size: 12px;
            line-height: 150%;
        }
        .small{
            margin-bottom: 10px;
            margin-top: 15px;
            color: #98A0AE;
            font-weight: 400;
            font-size: 10px;
        }
        .edit-btn{
            right: 13px;
            top: 13px;
        }
    }
}

.weekly-science-update{
    .grid-components{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    .card{
        margin: 0;
        padding: 0;
        .card-body{
            padding: 30px 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .card-title{
            font-size: 16px;
            margin-bottom: 10px;
        }
        .btn-white{
            width: 96px;
            font-weight: 500;
            font-size: 12px;
        }
        .description{
            font-size: 12px;
            line-height: 150%;
        }
        .small{
            margin-bottom: 8px;
            margin-top: 0;
            color: #98A0AE;
            font-weight: 400;
            font-size: 10px;
        }
        .edit-btn{
            right: 13px;
            top: 13px;
        }
    }
}

.scientific-papers{
    .grid-components{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .card{
        margin: 0;
        padding: 0;
        min-height: 105px;
        .card-body{
            padding: 16px 24px;
            height: 100%;
            display: grid;
            grid-template-columns: 6fr 1fr;
            gap: 10px;
            align-items: center;
        }
        .card-title{
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 16px;
        }
        .btn-white{
            width: 96px;
            font-weight: 500;
            font-size: 12px;
        }
        .description{
            font-size: 12px;
            line-height: 150%;
            margin-bottom: 0;
        }
        .small{
            margin-bottom: 8px;
            margin-top: 0;
            color: #98A0AE;
            font-weight: 400;
            font-size: 10px;
        }
        .edit-btn{
            right: 13px;
            top: 13px;
        }
    }
}

.featured-articles{
    .grid-components{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    .card{
        margin: 0;
        padding: 0;
        &:first-child{
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
            .card-body{
                flex-direction: column;
                justify-content: flex-end;
                .card-content{
                    order: 1;
                }
                img{
                    width: 100%;
                    height: 280px;
                }
            }
        }
        &:nth-child(even) {
            .card-content{
                order: 1;
                width: 100%;
            }
        }
        .card-body{
            padding: 12px;
            display: flex;
            gap: 12px;
            justify-content: space-between;
            img{
                height: 217px;
                width: 192px;
                object-fit: cover;
                box-shadow: 0px 3px 4px -1px rgba(13, 31, 64, 0.03);
                border-radius: 6px;
            }
        }
        .card-title{
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 16px;
        }
        .btn-white{
            width: 96px;
            font-weight: 500;
            font-size: 12px;
        }
        .description{
            font-size: 12px;
            line-height: 150%;
            margin-bottom: 10px;
        }
        .card-content{
            margin-top: 20px;
        }
        .small{
            margin-bottom: 8px;
            margin-top: 0;
            color: #98A0AE;
            font-weight: 400;
            font-size: 10px;
        }
        .edit-btn{
            right: 15px;
            top: 15px;
        }
        a.more{
            font-size: 12px;
            line-height: 18px;
            color: #0F75BC;
            font-weight: 500;
            background: none!important;
            border: none!important;
            box-shadow: none!important;
            width: auto;
            padding: 0;
        }
    }
}


.weekly-update, .science-news, .milestones-achieved-and-timelines{
    .grid-components{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    .card{
        margin: 0;
        padding: 0;
        .card-body{
            padding: 12px;
            display: flex;
            gap: 12px;
            justify-content: space-between;
            img{
                height: 217px;
                width: 192px;
                object-fit: cover;
                box-shadow: 0px 3px 4px -1px rgba(13, 31, 64, 0.03);
                border-radius: 6px;
            }
        }
        .card-title{
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 16px;
        }
        .btn-white{
            width: 96px;
            font-weight: 500;
            font-size: 12px;
        }
        .description{
            font-size: 12px;
            line-height: 150%;
            margin-bottom: 20px;
        }
        .card-content{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            margin-bottom: 20px;
        }
        .small{
            margin-bottom: 8px;
            margin-top: 0;
            color: #98A0AE;
            font-weight: 400;
            font-size: 10px;
        }
        .edit-btn{
            right: 15px;
            top: 15px;
        }
        a.more{
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #44526C;
            width: 99px;
            height: 32px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}


.video-based-news{
    .grid-components{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }
    .card{
        margin: 0;
        padding: 0;
        &:nth-child(1) {
            grid-column-start: 2;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 3;
            .card-body{
                flex-direction: column;
                justify-content: flex-end;
                .card-content{
                    order: 1;
                }
                img{
                    width: 100%;
                    height: 456px;
                }
            }
        }
        &:nth-child(1), &:nth-child(2),  &:nth-child(3){
           .card-content{
                height: 0;
                display: block;
                margin: 0;
                padding: 0;
                .description{
                    display: none;
                }
                .card-title{
                    position: absolute;
                    bottom: 30px;
                    left: 20px;
                    right: 20px;
                    margin: 0 auto;
                    color: white;
                }
                a.more{
                    display: none;
                }
           }
           .card-body{
               padding: 0;
               gap: 0;
           }
        }
        .card-body{
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 30px;
            display: flex;
            gap: 12px;
            justify-content: space-between;
            flex-direction: column;
            img{
                height: 217px;
                width: 100%;
                object-fit: cover;
                box-shadow: 0px 3px 4px -1px rgba(13, 31, 64, 0.03);
                border-radius: 0;
            }
        }
        .card-title{
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 16px;
        }
        .description{
            font-size: 12px;
            line-height: 150%;
            margin-bottom: 20px;
        }
        .card-content{
            margin-top: 20px;
            order: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 16px;
        }
        .small{
            display: none;
        }
        .edit-btn{
            right: 15px;
            top: 15px;
        }
        a.more{
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FFFFFF;
            background: #0F75BC;
            font-weight: 500;
            width: auto;
            padding: 0;
            height: 32px;
            justify-content: center;
            width: 100px;
            border: none;
        }
    }
}


.back-to-communications{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    position: relative;
    padding-left: 20px;
    &:before{
        position: absolute;
        content: '';
        background-size: 15px;
        padding: 7px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../assets/icons/arrow_down_c.svg");
        transform: rotate(90deg);
        background-size: 10px;
        left: 0;
        top: -1px;
    }
}


//media communications category pages
@media screen and (max-width: 1250px) {
    .communications-category-page{
        display: block;
    }
    .back-to-communications{
        margin-bottom: 20px;
        display: block;
    }
}

@media screen and (max-width: 1055px) {
    .featured-articles .grid-components{
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 944px) {
    .suggested-news .grid-components{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .weekly-update .grid-components, .science-news .grid-components, .milestones-achieved-and-timelines .grid-components{
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 784px) {
    .video-based-news .grid-components{
        grid-template-columns: 1fr 1fr;
    }
    .video-based-news .card:nth-child(1){
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
    }
    .video-based-news .card:nth-child(1) .card-body img{
        height: 217px;
        width: 100%;
    }
}

@media screen and (max-width: 660px) {
    .communications-category-page{
        margin-left: -15px;
        margin-right: -15px;
    }
    .suggested-news .grid-components{
        grid-template-columns: 1fr 1fr;
    }
    .featured-articles .card .card-body{
        flex-direction: column-reverse;
    }
    .featured-articles .card .card-body img{
        width: 100%;
    }
    .featured-articles .card:nth-child(even) .card-body{
        flex-direction: column;
    }
    .featured-articles .card .card-content{
        margin-top: 10px;
    }
}

@media screen and (max-width: 660px) {
    .weekly-science-update .grid-components {
        grid-template-columns: 1fr;
    }
    .scientific-papers .card .card-body{
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 560px) {
    .video-based-news .grid-components{
        grid-template-columns: 1fr;
    }
    .video-based-news .card:nth-child(1){
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
    }
}

@media screen and (max-width: 440px) {
    .suggested-news .grid-components{
        grid-template-columns: 1fr;
    }
    .weekly-update .card .card-body, .science-news .card .card-body, .milestones-achieved-and-timelines .card .card-body{
        flex-direction: column-reverse;
    }
    .weekly-update .card .card-body img, .science-news .card .card-body img, .milestones-achieved-and-timelines .card .card-body img{
        width: 100%;
    }
    .weekly-update .card .card-content, .science-news .card .card-content, .milestones-achieved-and-timelines .card .card-content{
        margin-top: 8px;
    }
}

// Circle loader
[data-loader="circle-side"] {
    position: absolute;
    -webkit-animation: circle infinite .95s linear;
    -moz-animation: circle infinite .95s linear;
    -o-animation: circle infinite .95s linear;
    animation: circle infinite .95s linear;
    border: 2px solid #0062cc;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-right-color: rgba(0, 0, 0, 0.2);
    border-bottom-color: rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    top: 4px;
    left: 5px;
}

@-webkit-keyframes circle {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes circle {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes circle {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes circle {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.btn {

    position: relative;
    transition: padding-left 0.5s;

    [data-loader="circle-side"]{
        visibility: hidden;
        opacity: 0;
        transition: all 0.4s;
    }

    &.loading{

        padding-left: 32px;

        [data-loader="circle-side"]{
            opacity: 1;
            visibility: visible;
            position: absolute;
            width: 20px;
            height: 20px;
            border: 3px solid #0062cc;
            border-top-color: rgba(0, 0, 0, 0.2);
            border-right-color: rgba(0, 0, 0, 0.2);
            border-bottom-color: rgba(0, 0, 0, 0.2);
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            -ms-border-radius: 100%;
            border-radius: 100%;
            margin-top: 0;
            margin-left: 0;
            width: 20px;
            height: 20px;
            top: 7px;
            left: 7px;
        }

    }

    &.btn-sm{

        &.loading{

            padding-left: 32px;

            [data-loader="circle-side"]{
                opacity: 1;
                visibility: visible;
                position: absolute;
                width: 20px;
                height: 20px;
                border: 3px solid #0062cc;
                border-top-color: rgba(0, 0, 0, 0.2);
                border-right-color: rgba(0, 0, 0, 0.2);
                border-bottom-color: rgba(0, 0, 0, 0.2);
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                -ms-border-radius: 100%;
                border-radius: 100%;
                margin-top: 0;
                margin-left: 0;
                width: 20px;
                height: 20px;
                top: 4px;
                left: 5px;
            }

        }

    }

}

.text-grey{
    color: #7C8698;
}

.text-dark-grey{
    color: #44526C;
}

.font-weight-500{
    font-weight: 500;
}

.font-weight-700{
    font-weight: 700;
}

.btn-rounded{
    border-radius:20px;
}

#notifications{

    list-style-type: none;

    // Notifications
    .notificationbox {
        background-color: #ffffff;
        min-width: 160px;
        z-index:99;
        top: 46px;
        align-items: baseline;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0px 3px 50px rgb(4 15 34 / 13%);
        flex-direction: column;
        gap: 10px;
        border: none;

        width: 400px;
        left: unset;

        .settings_icon{
            width: 19px;
            cursor: pointer;
            float: right;
            margin-top: 4px;
            margin-left: 10px;
        }

        .inner {
            transform: rotate(45deg);
            background-color: white;
            width: 100px;
            height: 100px;
            top: 20px;
            left: -50px;
            position: relative;
            border-radius: 20px;
        }

        .outer {
            position: absolute;
            top: -74px;
            left: 0;
            right: 0;
            width: 70px;
            height: 140px;
            overflow: hidden;
            margin: 0 auto;
            transform: rotate(
            -90deg
            ) scale(0.2);
        }
        ul {

            padding-left: 0;

            li {

                list-style-type: none;

                .drop-title {

                    font-weight: 500;
                    padding: 11px 20px 15px;
                    border-bottom: 1px solid #e2e2e2;

                    button.mark_all_read{

                        float: right;
                        background-color: transparent;
                        font-size:12px;
                        border:0;
                        color: #333333;
                        cursor: pointer;
                        text-decoration: underline;
                        margin-top:3px;

                    }

                }

                .nav-link {

                    border-top: 1px solid #666;
                    padding-top: 15px;

                }

            }

        }

        .message-center {

			height: 350px;
			overflow-y: scroll;
			position: relative;
			overflow-x: clip;

            a {

                border-bottom: 1px solid #666;
                display: block;
                text-decoration: none;
                padding: 9px 15px;
                cursor: pointer;
                position: relative;

                i.loading {

                    font-size: 22px;
                    height: 30px;
                    width: 30px;
                    position: absolute;
                    top: 50%;
                    display: block;
                    right: 16px;
                    margin-top: -18px;

                }

                &:hover {
                    background: rgba(0, 0, 0, 0.05);
                }

                &.read{

                    background-color: rgba(0,0,0,0.2);

                    .btn{
                        background-color: #455a64;
                        border: 1px solid #455a64;
                        box-shadow: none;
                    }

                    .notification-content{
                        h5{
                            color: #0D1F40;
                        }
                    }

                }

                div {
                    white-space: normal;

                    i{
                        font-size:23px;
                        line-height:28px;
                        height:28px;
                    }

                }

                .user-img {

                    width: 40px;
                    position: relative;
                    display: inline-block;
                    margin: 0 10px 15px 0;

                    img {
                        width: 100%;
                    }

                    .profile-status {
                        border: 2px solid $white;
                        border-radius: 50%;
                        display: inline-block;
                        height: 10px;
                        left: 30px;
                        position: absolute;
                        top: 1px;
                        width: 10px;
                    }
                    .online {
                        background: $success;
                    }
                    .busy {
                        background: $danger;
                    }
                    .away {
                        background: $warning;
                    }
                    .offline {
                        background: $warning;
                    }
                }



                .notification-content {
                    display: inline-block;
                    width: 100%;
                    padding-left: 10px;
                    vertical-align: middle;

                    .notification-title{
                        margin: 5px 0px 0;
                        font-size: 16px;
                        font-weight: 500;
                    }

                    .time {
                        font-size: 12px;
                        display: block;
                        margin: 1px 0;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    .notification-desc{
                        font-size: 12px;
                        display: block;
                        margin: 1px 0;
                    }

                }
            }
        }
    }

}




// Dropdown menu

// The dropdown menu (ul)
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none; // none by default, but block on "open" of the menu
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0; // override default ul
    list-style: none;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    background-color: $white;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    @include box-shadow(0 6px 12px rgba(0,0,0,.175));
    background-clip: padding-box;

    // Aligns the dropdown menu to right
    //
    // Deprecated as of 3.1.0 in favor of `.dropdown-menu-[dir]`
    &.pull-right {
        right: 0;
        left: auto;
    }

    // Dividers (basically an hr) within the dropdown
    .divider {
        @include nav-divider(#e5e5e5);
    }

    // Links within the dropdown menu
    > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: $line-height-base;
        color: $dropdown-link-color;
        white-space: nowrap; // prevent links from randomly breaking onto new lines
    }
}


/*******************
Notify
*******************/

.notify {

    position: absolute;
    left: 59px;
    top: 17px;

    .heartbit {
        position: absolute;
        top: -21px;
        right: -5px;
        height: 28px;
        width: 28px;
        z-index: 10;
        border: 5px solid #e3342f;
        border-radius: 70px;
        -moz-animation: heartbit 1s ease-out;
        -moz-animation-iteration-count: infinite;
        -o-animation: heartbit 1s ease-out;
        -o-animation-iteration-count: infinite;
        -webkit-animation: heartbit 1s ease-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    .point {
        width: 6px;
        height: 6px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        background-color: #e3342f;
        position: absolute;
        right: 6px;
        top: -10px;
    }
}

@-webkit-keyframes heartbit {
    0% {
        -webkit-transform: scale(0);
        opacity: 0.0;
    }

    25% {
        -webkit-transform: scale(0.1);
        opacity: 0.1;
    }

    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }

    75% {
        -webkit-transform: scale(0.8);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0.0;
    }
}


// Calendar

.dhx_cal_container{
    height: 700px;
}
.navbar-expand-md .navbar-nav .dropdown-menu {
    min-width: 222px;
    border-radius: 10px;
    top: 33px;
    left: unset;
    right: unset;
}

.dropdown-menu.dropdown-menu-right.show{
    background-color: #ffffff;
    min-width: 160px;
    z-index: 9999;
    top: 41px;
    align-items: baseline;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 3px 50px rgb(4 15 34 / 13%);
    flex-direction: column;
    gap: 15px;
    display: flex;
    border: none;
    padding: 23px 22px;
    a {
        padding: 0;
    }
}


.dhx_cal_tab_standalone{
    @media(max-width: 600px){
        display: none;
    }
}



.dhx_grid_event{

    td{
        font-weight: 500;
        font-size: 14px;
    }

}

#science-experiment-result{
    table{
        width: 100%;
        th, td{
            border: 1px solid #dee2e6;
        }

        th{
            background-color: #efefef;
        }
    }
}


#news{

    .keywords {
        color: #999;
    }

    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        margin: 0 10px;
        text-align: left;
    }

    a {
        color: #42b983;
    }

    .date a {
        background: #6c757d;
        padding: 5px 4px;
        display: flex;
        border-radius: 3px 0 0 3px;
    }

    .date img {
        background: #6c757d;
        width: 19px;
    }

    .date span {
        background: #545b62;
        color: white;
        padding: 6px 14px;
        padding-top: 7px;
        font-size: 13px;
    }

    .container {
        max-width: 1200px;
        margin: 0 auto;
    }

    .title {
        text-align: left;
        font-size: 20px;
        font-weight: 400;
        margin-left: 6px;
        margin-bottom: 0;
        margin: 0;
        color: #0071ba;
        text-decoration: none;
    }

    .element {
        display: flex;
        border-radius: 5px;
        margin-bottom: 20px;
        max-width: 865px;
    }

    .index {
        margin-top: 3px;
        font-size: 18px;
        margin-right: 14px;
    }

    .link {
        margin: 0;
        text-align: left;
        color: #006837;
        margin-top: 1px;
        display: block;
        text-decoration: none;
    }

    .logo img {
        width: 182px;
        margin-top: 11px;
    }

    ul {
        margin-left: 20px;
        margin-top: 0;
    }

    .nod > span {
        padding-left: 15px;
    }

    .wtree li {
        list-style-type: none;
        margin: 10px 0 10px 10px;
        position: relative;
    }

    .wtree li:before {
        content: "";
        position: absolute;
        top: -10px;
        left: -20px;
        border-left: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        width: 20px;
        height: 15px;
    }

    .wtree li:after {
        position: absolute;
        content: "";
        top: 5px;
        left: -20px;
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        width: 20px;
        height: 100%;
    }

    .wtree li:last-child:after {
        display: none;
    }

    .wtree li span {
        display: block;
        border: 1px solid #ddd;
        padding: 11px 10px;
        color: #888;
        text-decoration: none;
        position: relative;
        padding-left: 15px;
        border-radius: 6px;
        overflow: hidden;
    }

    .wtree li span .color {
        border: none;
        position: absolute;
        background: #b6d5e5;
        left: 0;
        top: -7px;
        bottom: -26px;
        padding: 0;
        width: 7px;
    }

    .wtree li span:hover,
    .wtree li span:focus {
        background: #eee;
        color: #000;
        border: 1px solid #aaa;
    }

    .wtree li span:hover + ul li:after,
    .wtree li span:hover + ul li:before,
    .wtree li span:focus + ul li:after,
    .wtree li span:focus + ul li:before {
        border-color: #aaa;
    }

    .wtree li.result-group span {
        border: none;
        border-radius: 0px;
    }

    .pub-date {
        color: #999;
        font-size: 12px;
        margin-bottom: 11px;
    }

}


.swal2-popup{
    border-radius: 2px !important;

    .swal2-title{
        font-weight: 400;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .swal2-content{
        line-height: 35px;
        font-size: 16px;
    }

}

.card{
    padding: 50px;
    border: 1px solid #EBECF0;
    border-radius: 8px;
    background-color: #FFFFFF;
}

.search-me{

    position: relative;

    .message-center{

        background-color: #FFF;
        border: 1px solid #EBECF0;
        border-top: none;
        padding: 10px 10px 10px 15px;
        position: absolute;
        margin-top: 10px;
        border-radius: 10px !important;

        .result{
            color: #333;
            border-bottom: 1px solid #ececec;



            &:last-child{
                border-bottom: none;
            }

            .line{

                .result-title{
                    font-size: 18px;
                    font-weight: 400;
                    white-space: pre;
                    margin-top: 5px;
                    padding-right:10px;
                }

            }

            .subline{

                .small{
                    font-size: 13px;
                    color: #999;
                }

            }

        }

    }

}

.btn.btn-raph{
    background-color: #FFFFFF;
    border: 0.5px solid #DFE1E6;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
    border-radius: 100px;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #44526C;
    height: 32px;
    padding: 0 16px;
    outline: none;
}

.btn.btn-transparent{
    background: transparent;
    border: 0;
}

.tools .search-me{

    .line{

        .result-title{
            font-size: 12px !important;
            text-align: left !important;
        }

        a{
            font-weight: bold;
            font-size: 12px !important;
        }

    }





}

.border-none{
    border: none !important;
}

.border-bottom-left-radius-zero{
    border-bottom-left-radius: 0;
}

.border-bottom-right-radius-zero{
    border-bottom-right-radius: 0;
}

.border-bottom-none{
    border-bottom: none;
}



.circular {
    animation: rotate 2s linear infinite;
    height: 50px;
    transform-origin: center center;
    width: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;


    &.small {
        position: absolute;
        right: 17px;
        top: 0px;
        width: 25px;
        height: 25px;
        left: auto;
    }

}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}


.iti.iti--allow-dropdown{
    width: 100%;
}

@media print {
    iframe {
        display: none!important;
    }
}


.fullwidth{
    width: 100%;
}


.modal{
    max-width: 100vw;
    max-height: 100vh;
}


.no-link{
    text-decoration: none;

    &:hover{
        text-decoration: none;
    }

    cursor: default;
}


.bottom-cards{

    a {

        display: inline-block;
        width: 19%;
        text-align: center;
        margin-right: 0.5%;
        margin-left: 0.5%;

        .card{
            padding: 0;
            position: relative;
            height: 100px;
        }

        span{
            font-weight: bold;
            text-decoration: none;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:first-child{
            margin-left: 0;
            width: 19.5%;
        }

        &:last-child{
            margin-right: 0;
            width: 19.5%;
        }

        &:hover{

            div{
                background-color: #FAFAFA;
            }

        }
    }

}


.word-wraph-anywhere{
    word-wrap: anywhere;
}

#password_visibility_controls{
    position: absolute;
    right: 10px;
    top: 2px;
    cursor: pointer;
}

ul.file_list{

    padding-left: 0;

    li{
        list-style: none;
        border-bottom: 1px dashed #EBECF0;
        padding: 5px 0 5px 0;

        span{
            font-size: 12px;
            font-weight: 500;
        }

    }

}



/* Chrome, Safari, Edge, Opera */
input.number_no_arrows::-webkit-outer-spin-button,
input.number_no_arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input.number_no_arrows[type=number] {
    -moz-appearance: textfield;
}



.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}


.files_wrapper{

    position: relative;

    .files_bg{

        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 23px;
        text-align: center;
        //padding-top: 30px;

        svg{

        }

        span{

            color: #B4BAC4;

            .bigger{
                font-size: 150%;
            }

        }


    }

}


//
#approval_request_dashboard {

    .form-conteol


    .new_request_btn {
        background-color: #FFFFFF;
        border: 0.5px solid #DFE1E6;
        box-sizing: border-box;
        box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
        border-radius: 100px;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #44526C;
        height: 32px;
        padding: 0 16px;
        outline: none;
    }

    .fltpickr{

        border: none;
        background-color: transparent;
        color: #0F75BC;
        font-weight: 500;

        //&.flatpickr_one{
        //    border-top-right-radius: 0;
        //    border-bottom-right-radius: 0;
        //    border-right: 0;
        //}
        //
        //&.flatpickr_two{
        //    border-top-left-radius: 0;
        //    border-bottom-left-radius: 0;
        //    border-left: 0;
        //}

    }

    .list-group{

        .list-group-item {

            background-color: transparent;
            border: 0;
            border-bottom: 1px solid #EBECF0;
            padding: 12px 0 12px 0;
            cursor: pointer;

            &:last-child{
                border-bottom: none;
            }

            &:hover{
                //background-color: #EBECF0;
            }

            .container {
                padding: 0;


                .text-active {
                    color: #0f75bc;
                }

                .text-approved {
                    color: $indigo;
                }

                .text-rejected {
                    color: $danger;
                }

                .text-completed {
                    color: $green;
                }

                .text-closed {
                    color: $gray-800;
                }

                .text-light-grey {
                    color: #98A0AE;
                }

            }



        }

    }

    .loadmore{

        padding: 8px 48px;
        border: 1px solid #EBECF0;

        &:hover{
            background-color: #EBECF0 !important;
            box-shadow: none;
            color: $grey;
        }

    }

}


.white-space-nowrap{
    white-space: nowrap;
}



@media(max-width:1023px){

    .items-columns-container-header{
        display: none;

    }
}

@media(min-width:1024px){

    .items-columns-container {
        display: inline-grid;
        grid-template-columns: 30fr 30fr 15fr 15fr 20fr 20fr 20fr auto;
    }

    .items-columns-container-header{
        display: inline-grid;
        grid-template-columns: 30fr 30fr 15fr 15fr 20fr 20fr 20fr auto;

    }
}


.content-menu {
    list-style: none;
    padding: 0;
    margin: 10px 0px;

    li {
        display: inline-block;
        border-right: 1px solid #bbb;
        padding: 0px 10px;

        &:first-child {
            padding: 0 10px 0 0;
        }
        &:last-child {
            border-right: 0;
        }

        a {

            &:hover, &.active {
                color: #3490dc;
                text-decoration: underline;
            }
            color: #000;
        }
    }
}

.storage {
    .faq-title {
        margin-top: 30px;
    }
}

#faq-form {
    margin-top: 50px;

    input {
        margin-bottom: 15px;
    }

    button {
        margin-top: 15px;
    }
}


.toggle-button {
    margin-left: 10px;

    input[type="checkbox"] {
        visibility: hidden;
        display: none;
    }

    &.for-highlights {
        label {
            width: 107px;
            color: #fff;
            padding-left: 10px;
        }



        input[type="checkbox"] {
            & + label {
                background-color: #0F75BC;
                &:before {
                    transform: translateX(73px);
                    background-color: #FFF;
                }
            }
        }
    }

    label {
        display: flex;
        width: 55px;
        height: 32px;
        border: 3px solid;
        border-radius: 99em;
        position: relative;
        transform-origin: 50% 50%;
        cursor: pointer;
        border-color: #0F75BC;
        background-color: #0F75BC;
        font-size: 12px;
        padding-top: 3px;
        color: #fff;

        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            background-color: #fff;
            border-radius: 50%;
            top: 4px;
            left: 6px;
            border-color: #0F75BC;
        }
    }

    &.for-dataroom {
        label {
            width: 107px;
            padding-left: 34px;
        }
    }
}

ul.pagination {
    display: inline-block;
    padding: 0;
    margin: 35px auto;
}

ul.pagination li {
    display: inline-block;
    vertical-align: middle;
}

ul.pagination li a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
}

ul.pagination li a.active {
    background-color: #0F75BC;
    color: white;
}

ul.pagination li a:hover:not(.active) {background-color: #ddd;}


label{
    color: #44526C;
}


.status{

    &.pending{
        color: $grey;
    }

    &.ready{
        color: $blue;
    }

    &.picked_up{
        color: $purple;
    }

    &.completed{
        color: $green;
    }

}


label{
    color: #44526C;
}


.status{

    &.pending{
        color: $grey;
    }

    &.ready{
        color: $blue;
    }

    &.picked_up{
        color: $purple;
    }

    &.completed{
        color: $green;
    }

}

.date_range_filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	img {
		margin-right: 5px;
	}
	input {
		background: none;
		border: none;
		width: 87px;
		font-weight: 500;
		font-size: 12px;
		letter-spacing: 0.02em;
		color: #0F75BC;
		outline: none;
	}
	span {
		border-bottom: 2px solid #0F75BC;
		height: 2px;
		width: 13px;
		display: block;
		margin-right: 10px;
	}
}

.bg-success{
	background-color: #5DB082 !important;
}

.bg-warning{
	background-color: #F29D41 !important;
}


.something{

}