.container.reports {

    background: #FFFFFF;
    border: 1px solid #F4F5F7;
    box-sizing: border-box;
    box-shadow: 0px 3px 4px -1px rgba(13, 31, 64, 0.03);
    border-radius: 8px;
    padding: 0;

    &.labs{
        background: transparent !important;
        border: none;

        .experiment-top-nav{
            padding-right: 0px !important;
            padding-left: 0px !important;
        }

    }

    .experiment-top-nav {
        margin: 0;
        padding: 22px 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;



        h4 {
            width: unset;
        }
        .tools {
            display: flex;
            align-items: center;
            .add-labs, .add-tags {
                display: flex;
                gap: 12px;
                position: relative;
                padding-right: 18px;
                margin-right: 10px;
                &:after {
                    content: "";
                    width: 2px;
                    border-right: 2px solid #ebebeb;
                    position: absolute;
                    right: 0;
                    top: 8px;
                    bottom: 8px;
                    display: flex;
                    align-items: center;
                }
                h4 {
                    font-weight: 500;
                    font-size: 13px;
                    width: auto;
                    display: flex;
                    align-items: center;

                    color: #44526C;
                }
                .added, .add {
                    display: flex;
                    gap: 10px;
                    position: relative;
                    cursor: pointer;
                    .lab, .tag {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 6px 14px;
                        height: 30px;
                        left: 0px;
                        top: 0px;
                        background: #E7F5FF;
                        border-radius: 4px;
                        position: relative;
                        img {
                            position: absolute;
                            right: -7px;
                            cursor: pointer;
                            width: 16px;
                            height: 16px;
                        }
                        p{
                            font-weight: 500;
                            font-size: 12px;

                            display: flex;
                            align-items: center;
                            color: #1666A0;
                            margin: 0px;
                        };
                    }
                }
                .select-lab, .select-tag {
                    position: absolute;
                    top: 34px;
                    right: 0;
                    width: max-content;
                    background: #FFFFFF;
                    border: 0.5px solid #EBECF0;
                    box-sizing: border-box;
                    box-shadow: 0px 3px 4px -1px rgb(13 31 64 / 3%);
                    border-radius: 6px;
                    padding: 16px 18px;
                    z-index: 99;
                    label {
                        font-size: 12px;
                        line-height: 11px;
                        color: #606C82;
                        font-weight: 400;
                    }
                }
            }

        }
    }
}

.reports-table {
    .t-header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr 6fr 1fr;
        height: 48px;
        align-items: center;
        border-top: 1px solid #EBECF0;
        border-bottom: 1px solid #EBECF0;
        //padding-right: 4px;
        p {
            font-weight: 400;
            font-size: 10px;
            text-transform: uppercase;
            color: #7C8698;
            margin: 0;
            border-right: 1px solid #EBECF0;
            height: 100%;
            align-items: center;
            display: flex;
			justify-content: center;

            &:last-child {
                border-right: 0px;
            }
        }
        .mini {
            margin-left: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-right: 1px solid #EBECF0;
            height: 100%;
            .mini-group {
                display: flex;
                gap: 20px;
                margin-right: 20px;
                .circle {
                    width: 12px;
                    height: 12px;
                    background: #0F75BC;
                    border-radius: 50%;
                    display: block;
                }
                .square {
                    width: 12px;
                    height: 12px;
                    background: #837AD3;
                    border-radius: 2px;
                    display: block;

                    &.approximate{
                        border: 2px solid #837AD3;
                        background-color: #FFFFFF;
                    }

                }
                .triangle {
                    display: block;
                    width: 13px;
                    height: 13px;
                    background: url(../../assets/icons/triangle.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .completed {
                    display: block;
                    width: 13px;
                    height: 13px;
                    background: url(../../assets/icons/completed.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .legend-item {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    p {
                        font-weight: normal;
                        color: #44526C;
                        text-transform: capitalize;
                    }
                }
            }
            p {
                margin-left: 0;
                height: 100%;
                align-items: center;
                display: flex;
                border-right: 0px;
            }
        }
    }
    .t-body {
        position: relative;
        /* width */
        &::-webkit-scrollbar {
            width: 4px;
            position: absolute;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #EBECF0;
        }
        .t-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr 6fr 1fr;
            border-bottom: 1px solid #EBECF0;
			//height: 120px;
            &:last-child {
                border-bottom: 0px;
            }
            &:nth-last-child(2) {
				border-bottom: 0px;
            }
            &.bottom {
                position: sticky;
                bottom: 0;
                background: white;
                width: 100%;
                border-top: 1px solid #EBECF0;
                z-index: 9;
				padding: 6px 0 9px;

                .mo {
                    display: flex;
					justify-content: space-between;

					div{

						flex-grow: 1;
						text-align: center;

						span {
							font-weight: 400;
							font-size: 11px;
							color: #363638;
							margin: 15px 0px;
						}

					}
                }
            }
            h5 {
                font-weight: 400;
                font-size: 13px;
                color: #0D1F40;
            }
            .sq {
                border-right: 1px solid #EBECF0;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
				justify-content: center;
                font-size: 12px;
                color: #44526C;
				text-align: center;


                &:last-child {
					//
                }

                &.time {
                    position: relative;
                    border-right: 0;
                    padding-right: 0;

                    .current_time {
                        width: 0px;
                        top: -1px;
                        border-left: 1px solid #333;
                        height: calc(100% + 1px);
                        position: absolute;
                        z-index: 10;
                        opacity: 0.5;
                    }

					label{
						font-size: 12px;
					}

                    .progress-time {
                        display: flex;
                        width: 100%;
                        height: 20px;
                        position: relative;

						.month_block{
							height: 30px;
							display: flex;
							align-items: center;
							justify-content: center;
							position: absolute;
							top: 0;


							&.middle_block{
								top: 30px;
							}

							&.bottom_block{
								top: 60px;
							}


						}

                        .dashed-line{
                            border-top: 2px dashed #63BDFB;
                            width: 100%;
                            margin-top: -2px;
                        }


                        span {
                            cursor: pointer;
                            width: 12px;
                            height: 12px;
                            top: -7px;
                            left: 0;
                            position: absolute;
                            z-index: 9;

                            &.duration {
                                height: 2px;
                                border-top: 2px solid #63BDFB;
                                top: -2px;
                                z-index: 8;
                            }

							&.aproximate-start {
								border: 2px solid #0F75BC;
								background-color: #FFFFFF;
								border-radius: 50%;
							}

                            &.start {
                                background: #0F75BC;
                                border-radius: 50%;
                            }

							&.block{
								background-color: #999999;
								height: 7px;
							}

                            &.scheduled-end {

                                background: #837AD3;
                                border-radius: 2px;

                                &.approximate{
                                    border: 2px solid #837AD3;
                                    background-color: #FFFFFF;
                                }

                            }

                            &.amended {
                                width: 13px;
                                height: 13px;
                                background: url(../../assets/icons/triangle.svg);
                                background-repeat: no-repeat;
                                background-size: contain;
                            }

                            &.completed {
                                width: 13px;
                                height: 13px;
                                background: url(../../assets/icons/completed.svg);
                                background-repeat: no-repeat;
                                background-size: contain;
                                background-color: white;
                            }

							&.bg-yellow{
								background-color: #F29D41;
							}

							&.bg-green{
								background-color: #5DB082;
							}
                        }
                    }
                    .bg-lines {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: space-between;
                        position: absolute;
                        top: 0;
                        .line {
                            height: 100%;
                            display: flex;
                            border-right: 1px dashed gainsboro;
                        }
                    }
                }

                &.open{

                    a{
                        font-weight: 500;
                        font-size: 12px;
                        color: #0F75BC;
                        position: relative;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        &:after {
                            content: '';
                            display: block;
                            width: 8px;
                            height: 8px;
                            background: url(../../assets/icons/arrow-g.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }
        }
    }
}



.bg-active{
	background-color: #0F75BC !important;
	color: white;
}

.bg-completed{
	background-color: #00b004 !important;
	color: white;
}

.bg-rejected{
	background-color: #E74C3C !important;
	color: white;
}

.bg-closed{
	background-color: #640a0a !important;
	color: white;
}