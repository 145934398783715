
.secondary-menu {

    margin: 0;
    padding: 0;
    list-style: none;

    &__item {

        &--inline {
            display: inline-block;
        }

        &--block {
            display: block;
        }

    }

    &__link {
        color: $darkGrey
    }
}
