.file-detail-modal {

    text-align: center;

    .modal-dialog {
        width: 90%;
        max-width: initial;
        overflow: hidden;

        .modal-content {
            min-height: 90vh;

            .file-detail-modal-view {
                width: 100%;
                height: 90vh;
            }
        }
    }

    .modal-body {
        height: 100% !important;
        overflow: auto;
    }
}

#pdf-container {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    canvas {
        max-width: 100% !important;
        height: auto !important;
    }
}


@media print {
    .file-detail-modal {
        display: none !important;

    }
}
