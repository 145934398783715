.breadcrumbs {
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
        display: inline-block;
        margin-right: 5px;
        font-weight: bold;

        &__link {
            text-decoration: none;
            text-transform: capitalize;
            font-weight: 500;
        }

        &:after {
            content: '/';
            margin-left: 10px;
        }

        &:last-child {
            pointer-events: none;

            .breadcrumbs__item__link {
                color: #999;
            }

            &:after {
                content: '';
            }
        }
    }
}
