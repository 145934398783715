.search-alerts {
    &__container {
        background: #fff;
    }

    .content {
        padding: 25px 0;
    }

    &__groups {
        margin-top: 30px;
    }

    &__icon {
        color: #676767;
    }

    &__group {
        background: #fff;
        padding: 20px 20px 5px;
        margin: 0 0 35px;
        border-radius: 10px;
        border: 1px solid #eee;
        box-shadow: 0 3px 0px #eee;
        cursor: pointer;
        overflow: hidden;

        &__head {
            display: flex;
            align-items: center;
        }

        &__title {
            margin: 0;
            font-weight: 600;
        }

        &__order {
            background: #eee;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            text-align: center;
            line-height: 30px;
            font-weight: bold;
            font-size: 12px;
            margin-right: 10px;
        }

        &__inner-list {
            float: left;
            &-items {
                padding: 0;

            }

            &-item {
                display: inline-block;
                margin-right: 5px;
            }
        }

        &__edit-btn {
            font-weight: bold;
            float: right;
        }
    }

    &__subscriber{
        border: 1px solid #acb9bb;
        display: inline-block;
        padding: 3px 15px 3px 20px;
        background: #edf9ff;
        border-radius: 50px;
        margin-right: 15px;
        cursor: pointer;

        i {
            color: #acb9bb;
            vertical-align: middle;
            margin-left: 5px;

            &:hover {
                color: #000;
            }
        }
    }

    &__popup {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;

        &-content {
            position: relative;
            z-index: 4;
            width: 70%;
            left: 0;
            right: 0;
            margin: 30px auto 0;
            background: #fff;
            padding: 60px;
            border-radius: 10px;
            border: 1px solid #eee;
        }

        &-overlay {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            background: #000;
            opacity: .4;
        }

        $borderColor: #ddd;

        &-dynamic-form {
            &__row {
                display: flex;
                border-top: 1px solid $borderColor;
                border-left: 1px solid $borderColor;
                justify-content: space-between;


                &:last-child {
                    border-bottom: 1px solid $borderColor;
                }
            }

            &__keyword {
                display: flex;
                padding: 15px;
                border-right: 1px solid $borderColor;
                align-items: center;

                &-index {
                    margin: 0;
                    width: 28px;
                    height: 28px;
                    background: #ddd;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 11px;
                    line-height: 28px;
                    font-weight: 400;
                    color: #808080;
                    margin-right: 11px;
                    padding: 0;
                    flex-shrink: 0;
                }
            }


            &__field {
                width: 100%;
                border: 1px solid $borderColor;
                border-radius: 5px;
                padding: 5px 15px;

                &:focus {
                    outline: none;
                }
            }

            &__cell {
                border-right: 1px solid $borderColor;
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 700;
            }
        }
    }

    .btn--block {
        border: 0;
        border-top: 1px solid #d4d4d4;
        border-bottom: 1px solid #d4d4d4;
        background: 0;
        text-align: center;
        width: 100%;
        padding: 16px;
        margin-top: 23px;
        margin-bottom: 30px;
        color: #0F75BC;
        font-weight: 600;

        .btn-icon {
            margin-right: 10px;
        }
    }

}
