.login-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Gotham SSm A", "Gotham SSm B";
    background: rgba(255, 255, 255, 0.2);
    background-image: none;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 999;

    .card{
        padding: 40px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 88px;
        width: 440px;
        border: none;
        box-shadow: none!important;
        border-radius: 0;

        background: rgba(255, 255, 255, 0.2);
        background-image: url("../../assets/login_bg.png");
        background-color: #cfcfce;
        background-size: cover;
        background-repeat: no-repeat;
        h3 {
            color: white;
        }

        img{
            position: absolute;
            left: -690px;
            width: 570px;
            bottom: 120px;
        }
        form{
            .form-group{
                padding: 0 15px;
            }
            .btn-link{
                padding-bottom: 28px;
                font-family: "Gotham SSm A", "Gotham SSm B";
                font-style: normal;
                font-weight: 350;
                font-size: 14px;
                padding-left: 0;
                font-weight: 500;
                padding-top: 16px;
            }
            button{
                display: block;
                width: 100%;
                height: 44px;
                border-radius: 42px;
                font-weight: 500;
            }
        }
        h3{
            margin-bottom: 10px;
            font-size: 24px;
            font-weight: 600;
            font-family: "Gotham SSm A", "Gotham SSm B";
        }
        .card-body{
            padding: 0;
        }
        .form-group{
            margin-bottom: 0;
            margin-top: 24px;
        }
    }
    .login-mobile-logo{
        display: none;
    }
}

@media screen and (max-width: 1300px) {
    .login-wrapper .card img{
        left: -570px;
        width: 470px;
    }
}

@media screen and (max-width: 1099px) {
    .login-wrapper .card img{
        left: -370px;
        width: 270px;
    }
}

@media screen and (max-width: 900px) {
    .login-wrapper .card img{
        left: -270px;
        width: 200px;
    }
}

@media screen and (max-width: 768px) {
    .login-wrapper {
        display: grid;
        width: 100%;
        grid-template-rows: 1fr 1fr;
        justify-content: normal;
        align-items: inherit;
        .logo-form-mobile{
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                margin: 0;
                padding: 0;
            }
        }
        .login-mobile-logo{
            display: block;
            width: 200px;
            margin: 200px auto;
            margin-top: 200px;
        }
        .card{
            position: static;
            width: 100%;
            height: 437px;
            top: unset;
            box-shadow: 0px -3px 40px rgba(0, 0, 0, 0.07)!important;
            border-radius: 41px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            padding-bottom: 0;
            height: auto;
            padding-top: 55px;
            border-radius: 0;
            display: block;
            input{
                height: 50px;
            }
            img{
                display: none
            }
            form{
                .btn-link{
                    padding-top: 26px;
                }
                button{
                    height: 88px;
                    background: white;
                    border-radius: 0;
                    border: 0;
                    color: black;
                    font-size: 16px;
                    text-align: right;
                }
            }
        }
    }
}
