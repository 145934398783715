$tabletBreakpoint: '768px';
$powerpointDeckLowerBreakpoint: '1400px';

.slides {
    width: 100%;
    height: 100%;
    padding: 0;
    list-style-type: none;

    .intro-logo {
        width: 500px;
    }

    .slide-item {

        height: 100vh;
        width: 100vw;

        &.deck {
            border: 1px solid #ddd;
            height: 85vh;
            overflow: hidden;

            @media all and (max-width: $powerpointDeckLowerBreakpoint) {
                border: 0;
            }

            img {
                width: auto;
                margin: 0 auto;
                height: 100%;

                @media all and (max-width: $powerpointDeckLowerBreakpoint) {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }

    .slick-arrow {
        position: fixed;
        z-index: 99999;
        bottom: 40px;
        border: 0;
        outline: 0;
        width: 159px;
        height: 48px;
        box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
        border-radius: 100px;
        font-size: 16px;
        font-weight: 600;
        color: #44526C;
        transition: all 0.2s ease;

            &:hover{
                background: #efefef;
                color: #44526C;
                img{
                    margin-left: 25px;
                }
            }
            img{
                margin-left: 19px;
                position: relative;
                top: -1px;
                transition: all 0.2s ease;
            }


        z-index: 999999;

        &.slick-prev {
            left: 50px;

            @media screen and (max-width: $tabletBreakpoint) {
                bottom: 105px;
            }
        }

        &.arrow-next {
            right: 50px;
        }

        &.slick-disabled {
            visibility: hidden;
        }

        @media screen and (max-width: $tabletBreakpoint) {
            bottom: 36px;
            left: 0 !important;
            right: 0 !important;
            width: 85%;
            margin: 0 auto;
        }
    }

    .slick-dots {
        position: fixed;
        bottom: 40px;
        padding: 0;
        left: 0;
        right: 0;
        z-index: 999;
        list-style: none;
        text-align: center;

        @media all and (max-width: $tabletBreakpoint) {
            display: none !important;
        }

        li {
            margin: 0 5px;

            &.slick-active {
                button {
                    background: #fff;
                    border: 1px solid #bbb;
                }
            }

            button {
                padding: 0;
                border-radius: 50%;
                border: 0;
                background: rgb(150 156 164);
                font-size: 0px;
                line-height: 0;
                width: 8px;
                height: 8px;
                color: #fff;
            }
        }
    }
}

.splash-screens {
    .slick-dots li {
        display: inline-block;
    }
}

.with-ppt-deck {

    .slick-dots {
        display: none !important;
    }
}


.logout-confirmation {
    z-index: 99999999999999;
    position: fixed;
    background: #fff;
    max-width: 430px;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,.3);
    margin: 0 auto;
    left: 0;
    right: 0;
    height: 150px;
    padding: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .btn {
        margin: 0 5px;
    }
}

.glossary-tree {
    width: 80vw;
    max-width: 100%;
    border-radius: 4px;
    margin-left: 40px;

    @media all and (max-width: 769px) {
        margin-left: 0;
        width: 100%;
    }

    &--border {
        padding: 10px 30px;
        border: 1px solid #eee;
        box-shadow: 0px 0px 3px #eee;
    }

    &__icon {
        width: 25px;
        float: left;
        margin-right: 10px;
    }

    &__item {
        list-style: none;
        position: relative;

        &--restricted {
            pointer-events: none;
            filter: grayscale(1);
            cursor: not-allowed;

            &:hover {
                cursor: not-allowed;
            }
        }
    }

    &__push-button {
        position: absolute !important;
        right: 0;
        top: 0;
        color: #fff !important;
        border-radius: 50px;
        padding: 5px 20px;
        margin-top: 8px;
    }

    &__link {
        color: #000;
        font-weight: 600;
        border-bottom: 1px solid #eaeaea;
        width: 100%;
        display: block;
        padding: 15px 25px 15px 0px;
		//padding: 15px 25px;
        //background: url("../../assets/icons/arrow_down.svg") no-repeat left center;
        outline: 0;
        font-size: 13px;
    }

    .missing a {
        color: red;
    }

    .should-add {

        background: #bbb;

        a {
            color: #fff;
            border-bottom-color: gray;
        }
    }

}
